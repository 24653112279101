import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./app.tsx";

import { store } from "./store/store.tsx";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import Korean from "./pages/korean/korean.tsx";
import { MatchingPairsV1 } from "./pages/korean/matching_v1.tsx";
import { MatchingPairsV2 } from "./pages/korean/matching_v2.tsx";
import { MatchingPairsV3 } from "./pages/korean/matching_v3.tsx";
import { MatchingPairsV4 } from "./pages/korean/matching_v4.tsx";
import { MatchingPairsV5 } from "./pages/korean/matching_v5.tsx";
import { KoreanEditor } from "./pages/korean/korean_editor.tsx";
import { MatchingPairsV6 } from "./pages/korean/matching_v6.tsx";
// import "./interface.ts";

const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/korean/v1",
        element: (
            <div className="flex justify-center items-center h-full w-full">
                <MatchingPairsV1 />
            </div>
        ),
    },
    {
        path: "/korean/v2",
        element: (
            <div className="flex justify-center items-center h-full w-full">
                <MatchingPairsV2 />
            </div>
        ),
    },
    {
        path: "/korean/v3",
        element: (
            <div className="flex justify-center items-center h-full w-full">
                <MatchingPairsV3 />
            </div>
        ),
    },
    {
        path: "/korean/v4",
        element: (
            <div className="flex justify-center items-center h-full w-full">
                <MatchingPairsV4 />
            </div>
        ),
    },
    {
        path: "/korean/v5",
        element: (
            <div className="flex justify-center items-center h-full w-full">
                <MatchingPairsV5 />
            </div>
        ),
    },
    {
        path: "/korean/v6",
        element: (
            <div className="flex justify-center items-center h-full w-full">
                <MatchingPairsV6 />
            </div>
        ),
    },
    {
        path: "/korean/editor",
        element: (
            <div className="flex justify-center items-center h-full w-full">
                <KoreanEditor />
            </div>
        ),
    },
]);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
            <ToastContainer position="bottom-right" autoClose={1000} />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
