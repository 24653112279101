import { compose, configureStore } from "@reduxjs/toolkit";
import allReducers from "./reducers";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const store = configureStore({
  reducer: allReducers,
});

export type RootKardzState = ReturnType<typeof store.getState>;
export type KardzAppDispatch = typeof store.dispatch;
export const useKardzAppDispatch: () => KardzAppDispatch = useDispatch;
export const useKardzAppSelector: TypedUseSelectorHook<RootKardzState> =
  useSelector;
