import * as React from "react";
import {
    Box,
    Typography,
    Button,
    FormGroup,
    FormControlLabel,
    Switch,
    Skeleton,
    LinearProgress,
} from "@mui/material";
import { shuffleArray } from "../../components/utils/random";
import axios from "axios";
import { useKardzAppDispatch, useKardzAppSelector } from "../../store/store";
import { korean_update_words_list } from "../../store/actions/actionTypes";
import { toast } from "react-toastify";

interface Pair {
    [key: string]: string;
}

const _pairs: Pair = {
    study: "공부하다",
    eat: "먹다",
    sleep: "자다",
    work: "일하다",
    play: "놀다",
    read: "읽다",
    write: "쓰다",
    speak: "말하다",
    listen: "듣다",
    think: "생각하다",
    feel: "느끼다",
    see: "보다",
    hear: "듣다",
    smell: "냄새 맡다",
    taste: "맛보다",
    touch: "만지다",
    go: "가다",
    come: "오다",
    stand: "서다",
    sit: "앉다",
    head: "머리",
    face: "얼굴",
    eye: "눈",
    ear: "귀",
    nose: "코",
    mouth: "입",
    hand: "손",
    foot: "발",
    time: "시간",
    day: "날",
    year: "년",
    number: "숫자",
    color: "색깔",
};

interface wordSchema {
    _id?: string;
    alpha: string;
    value: string;
    meta: { correct: number; wrong: number };
}

interface slotSchema {
    id: number;
    value: { left: string | null; right: string | null };
    raw: wordSchema | null;
    active: boolean;
    side: "left" | "right";
    selected: boolean;
    state: "Correct" | "Wrong" | "Pending" | "Idle";
}

// const textToSpeak = "Hello, world!";

// function speakText(text: string) {
//     const utterance = new SpeechSynthesisUtterance(text);
//     speechSynthesis.speak(utterance);
// }

// // Example usage:
// //   const textToSpeak = 'Hello, world!';
// speakText(textToSpeak);

// V1 only consists of the matching 5 pairs and move ahead..
export function MatchingPairsV3() {
    const { all_words } = useKardzAppSelector((state) => state.korean);
    const [states, setStates] = React.useState<{
        score: number;
        correct: number;
        wrong: number;
        next: boolean;
        adaptive: boolean;
        progress: number;
        shuffle: boolean;
    }>({
        score: 0,
        correct: 0,
        wrong: 0,
        next: false,
        adaptive: true,
        progress: 0,
        shuffle: false,
    });

    const [loading, setLoading] = React.useState<boolean>(false);
    const [slots, setSlots] = React.useState<slotSchema[]>([]);
    // const [source, setSource] = React.useState<string>("1600");

    // Adaptive SLots, Wrong Options Will be added here for revisits.
    const [adaptiveSlots, setAdaptiveSlots] = React.useState<wordSchema[]>([]);
    const dispatch = useKardzAppDispatch();
    const dispatch_action = React.useCallback(
        (action: any) => dispatch(action),
        []
    );

    const fetch_words = async () => {
        setLoading(true);
        const url = "https://admin-io-vercel.vercel.app/kardz/list";
        try {
            const { data } = await axios.post(url, {});

            if (data.length) {
                let progress = 0;
                data.map((word: wordSchema) => {
                    let wrong = word.meta.wrong | 0;
                    let correct = word.meta.correct! | 0;
                    if (Math.abs(wrong - correct) >= 1) {
                        progress = progress + 1;
                    }
                });
                // console.log(
                //     "Progress : ",
                //     progress,
                //     progress / data.length,
                //     Math.floor(progress / data.length)
                // );
                setStates({
                    ...states,
                    progress: Math.floor((progress * 100) / data.length),
                });
                dispatch_action({
                    type: korean_update_words_list,
                    payload: [...data],
                });
            }
        } catch (error) {
            console.error("Error fetching document:", error);
            throw error; // Rethrow the error for further handling
        }
        setLoading(false);
    };

    const sync_word_server = async (word: any, correct: boolean) => {
        const url = "https://admin-io-vercel.vercel.app/kardz/update";

        let { meta } = word;
        if (correct) {
            if (!meta.correct) meta.correct = 1;
            else meta.correct = meta.correct + 1;
        } else {
            if (!meta.wrong) meta.wrong = 1;
            else meta.wrong = meta.wrong + 1;
        }

        try {
            const { data } = await axios.post(url, {
                ...word,
                meta,
            });
            toast.info("Updated!");
            // dispatch_action({ type: korean_update_words_list, payload: data });
        } catch (error) {
            console.error("Error fetching document:", error);
            throw error; // Rethrow the error for further handling
        }
    };

    const Init = () => {
        const _stack = states.adaptive ? 14 : 10;

        // get data
        let words: wordSchema[] = all_words?.length ? [...all_words] : [];

        // Sort words based on correct score. Or Just shuffle

        let refined_words: wordSchema[] = states.shuffle
            ? shuffleArray(words)
            : words.sort((a, b) => a.meta.correct - b.meta.correct);

        refined_words = refined_words.slice(0, 5);

        if (states.adaptive) {
            const entries = [...adaptiveSlots];
            refined_words = shuffleArray([
                ...refined_words,
                ...entries.splice(0, 2),
            ]);
            // const remainingObject = Object.fromEntries(entries);
            setAdaptiveSlots(entries);
        }
        const _slots = [];
        // console.log(new_pairs, adaptiveSlots);

        let stack = refined_words.length * 2;
        for (let i = 0; i < stack; i++) {
            let idx = Math.floor(i % (stack / 2));
            const slot: slotSchema = {
                id: i,
                value: {
                    left: refined_words[idx].value,
                    right: refined_words[idx].alpha,
                },
                raw: refined_words[idx],
                active: true,
                side: Math.floor(i / (stack / 2)) ? "left" : "right",
                selected: false,
                state: "Idle",
            };
            _slots.push(slot);
        }

        setSlots([
            ..._slots.slice(0, stack / 2),
            ...shuffleArray(_slots.slice(stack / 2)),
        ]);
    };

    const makeCleanUp = async () => {
        // console.log("Clean Up Run.");
        if (!slots.length) return;
        setSlots([
            ...slots.map((slot) => {
                if (slot.state === "Correct") {
                    return {
                        ...slot,
                        state: "Idle",
                        active: false,
                        selected: false,
                    } as slotSchema;
                } else if (slot.state === "Wrong") {
                    return {
                        ...slot,
                        state: "Idle",
                        active: true,
                        selected: false,
                    } as slotSchema;
                } else return { ...slot };
            }),
        ]);
        // check for next
        // let is_next = false;
        // let _slots = [...slots]
        // _slots.map((slot) => (!slot.active ? (is_next = true) : null));
        // if (is_next) setStates({ ...states, next: true });
    };

    const handleSelect = ({
        value,
        active,
        state,
        selected,
        side,
        id,
        raw,
    }: slotSchema) => {
        let updated_slots = [];

        let selected_slot_left: any = null;
        let selected_slot_right: any = null;

        // check for same value and Uncheck ( Maintain the Single selected)
        updated_slots = slots.map((_slot) => {
            let slot: slotSchema = _slot;
            // consider the Side as its a Break-point
            if (slot.side === side) {
                // selected or not
                if (selected) {
                    if (slot.id === id) {
                        return { ...slot, selected: false };
                    }
                } else {
                    if (slot.id === id) {
                        return { ...slot, selected: true };
                    } else return { ...slot, selected: false };
                }
            }
            return { ...slot };
        });

        // check for current selected wrong or correct
        updated_slots.map((_slot) => {
            let slot = _slot;
            if (slot.selected) {
                if (slot.side === "left") selected_slot_left = slot;
                else selected_slot_right = slot;
            }
        });

        // making sure that slots are not null
        if (selected_slot_left && selected_slot_right) {
            if (
                selected_slot_left?.value.left ===
                selected_slot_right?.value.left
            ) {
                // state is correct
                sync_word_server(selected_slot_left.raw, true);
                updated_slots = updated_slots.map((_slot) => {
                    let slot = _slot;
                    if (slot.side === "left") {
                        if (selected_slot_left!.id === slot.id) {
                            return { ...slot, state: "Correct" } as slotSchema;
                        }
                    } else {
                        if (selected_slot_right!.id === slot.id) {
                            return { ...slot, state: "Correct" } as slotSchema;
                        }
                    }
                    return { ...slot };
                });
                setStates({
                    ...states,
                    score: states.score + 1,
                    correct: states.correct + 1,
                });
            } else {
                // state is Wrong
                // let wrong_obj: Pair = {};
                // wrong_obj[selected_slot_left.value.left] =
                //     selected_slot_left.value.right;
                if (states.adaptive) {
                    setAdaptiveSlots([
                        ...adaptiveSlots,
                        selected_slot_left.raw,
                    ]);
                }

                sync_word_server(selected_slot_left.raw, false);
                updated_slots = updated_slots.map((_slot) => {
                    let slot = _slot;
                    if (slot.side === "left") {
                        if (selected_slot_left.id === slot.id) {
                            return { ...slot, state: "Wrong" } as slotSchema;
                        }
                    } else {
                        if (selected_slot_right.id === slot.id) {
                            return { ...slot, state: "Wrong" } as slotSchema;
                        }
                    }
                    return { ...slot };
                });
                setStates({
                    ...states,
                    score: states.score - 1,
                    wrong: states.wrong + 1,
                });
            }
        }

        setSlots([...updated_slots]);
    };

    const handleReset = () => {
        Init();
    };

    React.useEffect(() => {
        Init();
        return () => {};
    }, [all_words]);

    React.useEffect(() => {
        fetch_words();
        return () => {};
    }, []);

    React.useEffect(() => {
        setTimeout(() => {
            makeCleanUp();
        }, 300);
    }, [states]);

    return (
        <Box
            sx={{
                width: "100%",
                maxWidth: 360,
                margin: "auto",
                alignItems: "center",
                alignContent: "center",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
            }}
        >
            <Typography variant="h5" align="center" gutterBottom>
                Match The Pairs ✨
            </Typography>
            <Typography variant="h6" align="center" gutterBottom>
                <div className="flex gap-2 flex-row">
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                checked={states.adaptive}
                                onChange={(ada) =>
                                    setStates({
                                        ...states,
                                        adaptive: ada.target.checked,
                                    })
                                }
                            />
                        }
                        label="Adaptive"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                checked={states.shuffle}
                                onChange={(ada) =>
                                    setStates({
                                        ...states,
                                        shuffle: ada.target.checked,
                                    })
                                }
                            />
                        }
                        label="Shuffle"
                    />
                </div>
            </Typography>
            <Typography variant="h5" align="center" gutterBottom>
                <div className="flex gap-3 justify-center items-center">
                    <div className=" flex gap-1 bg-sky-100 rounded-md p-2">
                        <div className="bg-sky-200  rounded-md px-2">
                            Score :
                        </div>
                        <div className="bg-sky-200  rounded-md px-2">
                            {states.score}
                        </div>
                    </div>
                    <div className=" flex gap-2 bg-gray-100 rounded-md p-2">
                        <div className=" bg-green-300 rounded-md px-2">
                            {states.correct}
                        </div>
                        /
                        <div className="bg-red-300 rounded-md px-2">
                            {states.wrong}
                        </div>
                    </div>
                </div>
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", width: "300px" }}>
                <Box sx={{ width: "100%", mx: 1 }}>
                    <LinearProgress
                        variant="determinate"
                        color="success"
                        value={states.progress}
                    />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                    >{`${Math.round(states.progress)}%`}</Typography>
                </Box>
            </Box>
            {!loading ? (
                <div className="flex gap-10 justify-around">
                    <div className="flex flex-col gap-5 ">
                        {slots
                            .filter((slot) => slot.side === "left")
                            .map(
                                ({
                                    value,
                                    active,
                                    state,
                                    selected,
                                    side,
                                    id,
                                    raw,
                                }) => (
                                    <Button
                                        key={id}
                                        disabled={active ? false : true}
                                        variant={`${
                                            selected ? "contained" : "outlined"
                                        }`}
                                        color={`${
                                            state === "Correct"
                                                ? "success"
                                                : state === "Wrong"
                                                ? "error"
                                                : "info"
                                        }`}
                                        onClick={() =>
                                            handleSelect({
                                                value,
                                                active,
                                                state,
                                                selected,
                                                id,
                                                side,
                                                raw,
                                            })
                                        }
                                    >
                                        {value.left}
                                    </Button>
                                )
                            )}
                    </div>
                    <div className="flex flex-col gap-5 ">
                        {slots
                            .filter((slot) => slot.side === "right")
                            .map(
                                ({
                                    value,
                                    active,
                                    state,
                                    selected,
                                    side,
                                    id,
                                    raw,
                                }) => (
                                    <Button
                                        key={id}
                                        disabled={active ? false : true}
                                        variant={`${
                                            selected ? "contained" : "outlined"
                                        }`}
                                        color={`${
                                            state === "Correct"
                                                ? "success"
                                                : state === "Wrong"
                                                ? "error"
                                                : "info"
                                        }`}
                                        onClick={() =>
                                            handleSelect({
                                                value,
                                                active,
                                                state,
                                                selected,
                                                side,
                                                id,
                                                raw,
                                            })
                                        }
                                    >
                                        {value.right}
                                    </Button>
                                )
                            )}
                    </div>
                </div>
            ) : (
                <div className="flex gap-4 ">
                    {/* <Skeleton variant="rectangular" animation="wave" width={310} height={300} /> */}
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        width={150}
                        height={300}
                    />
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        width={150}
                        height={300}
                    />
                </div>
            )}

            <Box sx={{ textAlign: "center", marginTop: 2 }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleReset}
                    // disabled={!states.next}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}
