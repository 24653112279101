import React, { useCallback, useEffect, useState } from "react";
import { Button, Input, SelectOptions } from "react-utility-yard";

import { kardz_update_kard_type } from "../store/actions/actionTypes";
import { useKardzAppDispatch, useKardzAppSelector } from "../store/store";
import { get_list, set_curr_card } from "../store/actions";
import { toast } from "react-toastify";

type Props = {};

function Toolbar({}: Props) {
  const { kardzMenuList: indxs, KardzName: CurrIdx } = useKardzAppSelector(
    (state) => state.kardz
  );

  const [link_q, setLink_q] = useState("");
  const dispatch = useKardzAppDispatch();
  const dispatch_action = useCallback(
    (action: any) => dispatch(action),
    [dispatch]
  );

  const [loading_state, setLoading_state] = useState(false);

  const get_kardz_data = async () => {
    setLoading_state(true);
    dispatch_action(get_list(CurrIdx ? CurrIdx : ""));
    dispatch_action({ type: kardz_update_kard_type, payload: "keyPair" });
    setLoading_state(false);
  };

  const get_kardz_links = async (query = link_q) => {
    setLoading_state(true);
    toast.info("Feature Into Progress...");
    toast.info("Only Cards are available...");
    setLoading_state(false);
  };

  const get_kardz_words = async (n = 50) => {
    setLoading_state(true);
    toast.info("Feature Into Progress...");
    toast.info("Only Cards are available...");
    setLoading_state(false);
  };

  useEffect(() => {}, [indxs]);
  return (
    <div className="m-2 flex flex-col items-center gap-1 p-2">
      <div className="flex w-full flex-wrap items-center justify-center gap-1">
        {/* <div className="flex w-full items-center justify-center gap-1">
          <Input
            editOn={true}
            type="text"
            value={link_q}
            placeholder="link search"
            onkeyDown={(e: any) => {
              if (e.keyCode === 13 && link_q !== "") {
                get_kardz_links();
              }
            }}
            onchange={(value: any) => setLink_q(value)}
          />
          <Button text="Search" onclick={() => get_kardz_links()} />
          <Button text="50 Words (Random)" onclick={() => get_kardz_words()} />
        </div> */}
      </div>
      <div className="flex flex-wrap items-center justify-center gap-1">
        <div>
          <SelectOptions
            title="Cards"
            default_value={CurrIdx || ""}
            onClick={(idx: any) => dispatch_action(set_curr_card(idx))}
            options={indxs}
          />
        </div>

        <Button
          text="Fetch Data"
          onclick={() => get_kardz_data()}
          loading={loading_state}
        />
      </div>
    </div>
  );
}

export default Toolbar;
