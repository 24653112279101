// import * as React from "react";

const kardz = "https://admin-io-vercel.vercel.app/kardz";
// const kardz = "http://localhost:5000/kardz";

export const kardz_url_list = kardz + "/list";
export const kardz_url_update = kardz + "/update";
export const kardz_url_new = kardz + "/new";

export const categories = [
    "verb",
    "adjective",
    "counter with native", // to be used with native numbers
    "counter with sino", // to be used with sino numbers
    "things",
    "time",
    "pronoun/kinship",
    "pronoun indefinite",
    "pronoun interrogative",
    "location/position",
    "particles",
    "week days",
    "months",
    "native numbers",
    "sino numbers",
    "connectors",
    "conversation",
    "grammar",
];

export interface wordSchema {
    _id?: string;
    alpha: string;
    value: string;
    meta: { correct: number; wrong: number };
    category: string[];
}

export interface slotSchema {
    id: number;
    value: { left: string | null; right: string | null };
    raw: wordSchema | null;
    active: boolean;
    side: "left" | "right";
    selected: boolean;
    state: "Correct" | "Wrong" | "Pending" | "Idle";
}

export function speakText(
    text: string,
    lan: "En" | "Kr",
    en_audio: boolean = true,
    kr_audio: boolean = true
) {
    const utterance = new SpeechSynthesisUtterance(text);
    let _voice = "";
    if (lan === "Kr") {
        utterance.lang = "ko-KR";
        _voice = "Google 한국의";
        // let _voice = "Microsoft Heami - Korean (Korean)"
    }
    utterance.voice = speechSynthesis
        .getVoices()
        .find((voice) => voice.name === _voice)!; // Replace with the desired female voice name

    // Set rate and pitch for clarity
    utterance.rate = 0.9; // Adjust as needed
    utterance.pitch = 1.0; // Adjust as needed
    if ((en_audio && lan === "En") || (kr_audio && lan === "Kr"))
        speechSynthesis.speak(utterance);
}

export function formatTime(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const hoursString =
        hours > 0
            ? `${hours}h   
   `
            : "0h";
    const minutesString = minutes > 0 ? `${minutes}m ` : "0m";
    const secondsString = remainingSeconds > 0 ? `${remainingSeconds}s` : "0s";

    return { hoursString, minutesString, secondsString };
}
