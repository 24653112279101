export const KeyValKardz = ({
  data = { alpha: null, value: null },
  valueOn = true,
}) => {
  let { alpha, value } = data;
  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col items-center">
          <>
            <p className="text-center text-7xl md:text-9xl">
              {alpha}
              <span className="text-xs">{`alpha`}</span>
            </p>
            {valueOn && (
              <p className="text-center text-4xl md:text-5xl">
                {value}
                <span className="text-xs">{`value`}</span>
              </p>
            )}
          </>
        </div>
      </div>
    </>
  );
};
