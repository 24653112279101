export const kardz_toggle_game = "KARDZ_TOGGLE_GAME";
export const kardz_toggle_checkMode = "KARDZ_TOGGLE_CHECKMODE";
export const kardz_toggle_showAnswer = "KARDZ_TOGGLE_SHOWANSWER";
export const kardz_score_increment = "KARDZ_SCORE_INCREMENT";
export const kardz_score_decrement = "KARDZ_SCORE_DECREMENT";
export const kardz_next_idx = "KARDZ_NEXT_INDEX";
export const kardz_prev_idx = "KARDZ_PREV_INDEX";
export const kardz_soft_reset = "KARDZ_SOFT_RESET";
export const kardz_update_list = "KARDZ_UPDATE_LIST";
export const kardz_update_index_list = "KARDZ_UPDATE_INDEX_LIST";
export const kardz_update_kard_type = "KARDZ_UPDATE_CARDTYPE";
export const kardz_set_check_against = "KARDZ_SET_CHECK_AGAINST";
export const kardz_set_card_name = "KARDZ_SET_CARD_NAME";
export const korean_update_words_list = "KOREAN_UPDATE_WORDS_LIST";
