import React from "react";
import { Kardz } from "./components/kardz";

// type Props = {};

const App = () => {
  return <Kardz />;
};

export default App;
