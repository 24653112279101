import * as actionTypes from "../actions/actionTypes";

const korean_init_state: any = {
    all_words: [],
};

export const koreanReducer = (
    state: any = korean_init_state,
    action: any
): any => {
    switch (action.type) {
        case actionTypes.korean_update_words_list:
            let new_list: any[] = [];
            if (action.payload && action.payload.length > 0)
                new_list = [...action.payload];
            return { ...state, all_words: new_list };

        default:
            return { ...state };
    }
};
