import {
  Button,
  Input,
  ToggleSwitch,
  MaterialIcon,
  SelectOptions,
} from "react-utility-yard";
import { useState, useEffect, useCallback } from "react";
import { KeyValKardz } from "./kardz_types/key_value_kard";
import {
  kardz_next_idx,
  kardz_prev_idx,
  kardz_set_check_against,
  kardz_soft_reset,
  kardz_toggle_checkMode,
  kardz_toggle_game,
  kardz_toggle_showAnswer,
  kardz_update_list,
} from "../store/actions/actionTypes";
import { kardz_score_increment } from "../store/actions/actionTypes";
import { useKardzAppDispatch, useKardzAppSelector } from "../store/store";
import { toast } from "react-toastify";

const KeyPairKeys = ["alpha", "value"];

export function KardzVis() {
  const [ans, setAns] = useState("Null");
  const [res_screen, setRes_screen] = useState(false);
  const [page_color, setPage_Color] = useState("white");

  const [focus_on_next, setFocus_on_next] = useState(false);
  const [focus_on_input, setFocus_on_input] = useState(false);

  const dispatch = useKardzAppDispatch();
  const {
    gameOn: is_game,
    currentIdx: idx,
    checkMode: isCheck,
    cardType: kardType,
    checkAgainst: check_against,
    score: curr_score,
    showAnswer: show_ans,
    list,
  } = useKardzAppSelector((state) => state.kardz);

  const dispatch_action = useCallback(
    (type: any, payload?: any) =>
      dispatch({ type: type, payload: payload || null }),
    [dispatch]
  );

  const game_start = () => {
    if (!list || list?.length === 0) {
      toast.error("Please, Select a Card...");
      return;
    }
    dispatch_action(kardz_soft_reset);
    dispatch_action(kardz_toggle_game, true);
    setPage_Color("white");
    setAns("");
    // setIsAns(false);
    setRes_screen(false);
    setFocus_on_input(true);
  };
  const check_ans = () => {
    if (!list?.length) return;
    if (ans.toUpperCase() === list[idx][check_against!]?.toUpperCase()) {
      setRes_screen(false);
      setPage_Color("green");
      setTimeout(() => setPage_Color("white"), 1000);
      dispatch_action(kardz_score_increment);
      next(true);
    } else {
      setRes_screen(true);
      setPage_Color("red");
    }
    setFocus_on_next(true);
  };

  const next = (local_last = false) => {
    // local_last ? result_ans_feedback() : setPage_Color("white");
    // setIsAns(false);
    setRes_screen(false);
    setAns("");
    console.log(idx);
    if (idx === list.length - 1) {
      dispatch_action(kardz_soft_reset);
    } else {
      dispatch_action(kardz_next_idx);
    }
    isCheck ? setFocus_on_input(true) : setFocus_on_next(false);
  };

  const prev = (local_last = false) => {
    setRes_screen(false);
    setAns("");
    if (idx === list.length - 1) {
      dispatch_action(kardz_toggle_game, false);
    } else {
      dispatch_action(kardz_prev_idx);
    }
    // isCheck ? setFocus_on_input(true) : setFocus_on_next(false);
  };

  const randomize = () => {
    if (!list?.length) return;
    let array = [...list];
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    array = [...array.map((m) => ({ ...m }))];
    dispatch_action(kardz_update_list, array);
  };

  const toggle_check_mode = () => {
    if (!isCheck) {
      dispatch_action(kardz_toggle_checkMode, true);
      setFocus_on_next(false);
      setFocus_on_input(true);
    } else {
      dispatch_action(kardz_toggle_checkMode, false);
      setFocus_on_next(true);
      setFocus_on_input(false);
    }
  };

  useEffect(() => {
    // randomize();
    dispatch_action(kardz_soft_reset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "ArrowLeft") prev();
    });

    // cleanup this component
    return () => {
      window.removeEventListener("keydown", (e) => {
        if (e.key === "ArrowLeft") prev();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="m-2 flex h-auto flex-col items-center justify-between gap-2 rounded-lg">
      <div
        className={`flex h-[400px] w-full min-w-[360px] max-w-[750px] flex-col items-center justify-between rounded-lg bg-opacity-25 backdrop-blur-lg md:h-[500px] lg:w-[800px] ${
          page_color === "white"
            ? " bg-white"
            : page_color === "green"
            ? " bg-green-400 bg-opacity-50"
            : " bg-red-400 bg-opacity-50"
        }`}
      >
        <div className="flex flex-col items-center">
          <div className="m-1 flex flex-row flex-wrap items-center gap-1">
            <MaterialIcon icon={"pets"} />
            <MaterialIcon
              icon={"shuffle"}
              cursor="pointer"
              onClick={randomize}
            />
            {kardType === "keyPair" && (
              <div className="gap-1/2 m-1 flex flex-row flex-wrap items-center">
                {/* <Button text="Value visible" color="warm" /> */}
              </div>
            )}
          </div>
          <div className="flex flex-row">
            <div className="m-2">
              <p className="text-lg font-bold">{`[${idx}]`}</p>
            </div>
            <Score
              score={curr_score}
              total_score={list?.length}
              visible={isCheck}
            />
            {/* {last_Ans && <AknowledgementGif status={"success"} />} */}
          </div>
        </div>
        {is_game ? (
          <div className={""}>
            <KeyValKardz data={list?.[idx]} valueOn={show_ans} />
          </div>
        ) : (
          <>
            <Button text={"PLAY ▷"} onclick={() => game_start()} />
          </>
        )}
        <div className="m-2 flex flex-row gap-2">
          {isCheck && (
            <p className="text-lg font-bold">{ans === "" ? "..." : ans}</p>
          )}
        </div>
      </div>
      <div className="flex min-h-[100px] flex-col  justify-between rounded-lg bg-white bg-opacity-25 px-2 py-2  md:px-4">
        <div className="flex flex-row flex-wrap justify-center items-center gap-1">
          <SelectOptions
            options={KeyPairKeys}
            title="Check Against"
            default_value={"value"}
            onClick={(value: any) =>
              dispatch_action(kardz_set_check_against, value)
            }
          />
          <div className="flex flex-row flex-wrap items-center gap-1 bg-white rounded-md px-1">
            <ToggleSwitch
              key={"kjdfkjdsdfk"}
              color="blue"
              size="sm"
              text="check mode"
              check={isCheck}
              onChange={() => toggle_check_mode()}
            />
            <ToggleSwitch
              key={"kjdfkjdk"}
              color="blue"
              size="sm"
              text="Show Ans"
              check={show_ans}
              onChange={() =>
                dispatch_action(kardz_toggle_showAnswer, !show_ans)
              }
            />
          </div>
        </div>
        <div className="flex flex-row justify-center gap-1">
          {/* input field when cheking mode is on and IsAns is false. */}
          {isCheck && !res_screen ? (
            <Input
              editOn={true}
              value={ans}
              onchange={(value: any) => setAns(value)}
              placeholder={"Type here"}
              // color={"white"}
              // width={"250px"}
              focus={focus_on_input}
              onkeyDown={(e: any) => {
                if (e.key === "Enter") {
                  console.log("ENTER");
                  check_ans();
                }
              }}
            />
          ) : (
            <div className="flex gap-1">
              <Button
                text={"Prev"}
                onclick={() => {
                  prev();
                }}
              />
              <Button
                text={"Next"}
                onclick={() => {
                  setPage_Color("white");
                  next();
                }}
                focus={focus_on_next}
                onkeydown={(e: any) => {
                  console.log("im clieckd");
                  if (e.key === "Enter") {
                    next();
                  }
                }}
              />
            </div>
          )}
        </div>
        {/* <Button
          text={"Random"}
          onclick={() => {
            randomize();
          }}
        /> */}
      </div>
    </div>
  );
}

export const Score = ({ score = 0, total_score = 0, visible = true }) => {
  return (
    <>
      {visible ? (
        <div className="m-2">
          <p className="text-xl font-bold">{`Score ${score}/${total_score}.`}</p>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
