import * as React from "react";
import { Box, Typography, Button } from "@mui/material";
import { toast } from "react-toastify";
import { shuffleArray } from "../../components/utils/random";

interface Pair {
    [key: string]: string;
}
interface PairState {
    left: string;
    right: string;
    state: "In-Active" | "Active";
}

const _pairs: Pair = {
    "to teach": "가르치다",
    plan: "계획",
    "to like": "좋아하다",
    travel: "여행",
    "to rest": "쉬다",
    work: "일",
    play: "놀다",
    study: "공부하다",
    read: "읽다",
    write: "쓰다",
    talk: "말하다",
    listen: "듣다",
    think: "생각하다",
    feel: "느끼다",
    see: "보다",
    hear: "듣다",
    smell: "맡다",
    taste: "맛보다",
    touch: "만지다",
    go: "가다",
    come: "오다",
    stay: "머무르다",
    sit: "앉다",
    stand: "서다",
    lie: "눕다",
};
const InitState: PairState[] = shuffleArray(Object.entries(_pairs))
    .slice(0, 5)
    .map(([key, value]) => ({
        left: key, // 한국어를 왼쪽에 배치
        right: value, // 영어를 오른쪽에 배치
        state: "Active", // 모든 항목의 상태를 'Active'로 설정
    }));

// V1 only consists of the matching 5 pairs and move ahead..
export function MatchingPairsV1() {
    
    const [pairs, setPairs] = React.useState<PairState[]>(
        InitState as PairState[]
    );

    const [selectedPair, setSelectedPair] = React.useState<{
        right: string | null;
        left: string | null;
    }>({
        left: null,
        right: null,
    });
    const [prevSelectedPair, setPrevSelectedPair] = React.useState<{
        prevRight: string | null;
        prevLeft: string | null;
    }>({
        prevLeft: null,
        prevRight: null,
    });
    const [selectedPairState, setSelectedPairState] = React.useState<{
        status: "Correct" | "Wrong" | "Pending" | "Idle";
    }>({
        status: "Idle",
    });

    const makeInactive = async (left: string) => {
        if (!pairs.length) return;
        // console.log(pairs);
        await setPairs([
            ...pairs.map((pair) => {
                if (pair.left === left) {
                    // console.log("In-Active Done!");
                    return { ...pair, state: "In-Active" } as PairState;
                }
                return { ...pair } as PairState;
            }),
        ]);
    };

    const handleLeftSelect = (left: string) => {
        if (left === selectedPair.left) {
            setSelectedPair({ ...selectedPair, left: null });
        } else if (selectedPair.right === null) {
            setSelectedPair({ ...selectedPair, left });
        } else if (
            pairs.find((pair) => pair.left === left)?.right ===
            selectedPair.right
        ) {
            // Correct Match
            toast.info("Correct");
            setPrevSelectedPair({
                ...selectedPair,
                prevLeft: left,
                prevRight: selectedPair.right,
            });
            setSelectedPair({
                ...selectedPair,
                left: null,
                right: null,
            });
            setSelectedPairState({ ...selectedPairState, status: "Correct" });
            setTimeout(async () => {
                setPrevSelectedPair({
                    ...selectedPair,
                    prevLeft: null,
                    prevRight: null,
                });
                await makeInactive(left);
                setSelectedPairState({ ...selectedPairState, status: "Idle" });
            }, 500);
        } else {
            toast.error("Wrong");
            setPrevSelectedPair({
                ...selectedPair,
                prevLeft: left,
                prevRight: selectedPair.right,
            });
            setSelectedPair({
                ...selectedPair,
                left: null,
                right: null,
            });
            setSelectedPairState({ ...selectedPairState, status: "Wrong" });
            setTimeout(() => {
                setPrevSelectedPair({
                    ...selectedPair,
                    prevLeft: null,
                    prevRight: null,
                });
                setSelectedPairState({ ...selectedPairState, status: "Idle" });
            }, 500);
        }
    };

    const handleRightSelect = (right: string) => {
        if (right === selectedPair.right) {
            setSelectedPair({ ...selectedPair, right: null });
        } else if (selectedPair.left === null) {
            setSelectedPair({ ...selectedPair, right });
        } else if (
            pairs.find((pair) => pair.left === selectedPair.left)?.right ===
            right
        ) {
            // Correct Match
            toast.info("Correct");
            setPrevSelectedPair({
                ...selectedPair,
                prevLeft: selectedPair.left,
                prevRight: right,
            });
            setSelectedPair({
                ...selectedPair,
                left: null,
                right: null,
            });
            setSelectedPairState({ ...selectedPairState, status: "Correct" });
            setTimeout(async () => {
                setPrevSelectedPair({
                    ...selectedPair,
                    prevLeft: null,
                    prevRight: null,
                });
                await makeInactive(selectedPair.left as string);
                setSelectedPairState({ ...selectedPairState, status: "Idle" });
            }, 500);
        } else {
            toast.error("Wrong");
            setPrevSelectedPair({
                ...selectedPair,
                prevLeft: selectedPair.left,
                prevRight: right,
            });
            setSelectedPair({
                ...selectedPair,
                left: null,
                right: null,
            });
            setSelectedPairState({ ...selectedPairState, status: "Wrong" });
            setTimeout(() => {
                setPrevSelectedPair({
                    ...selectedPair,
                    prevLeft: null,
                    prevRight: null,
                });
                setSelectedPairState({ ...selectedPairState, status: "Idle" });
            }, 500);
        }
    };

    const handleReset = () => {
        // setSelectedPairs([]);
        setPairs(InitState);
        setSelectedPair({
            left: null,
            right: null,
        });
        setPrevSelectedPair({
            prevLeft: null,
            prevRight: null,
        });
    };

    return (
        <Box
            sx={{
                width: "100%",
                maxWidth: 360,
                margin: "auto",
                alignItems: "center",
                alignContent: "center",
                display: "flex",
                flexDirection: "column",
                gap: "40px",
            }}
        >
            <Typography variant="h6" align="center" gutterBottom>
                Select the matching pairs
            </Typography>
            <div className="flex gap-10 justify-around">
                <div className="flex flex-col gap-5 ">
                    {pairs &&
                        pairs.map &&
                        pairs.map(({ left: value, right, state }) => (
                            <Button
                                disabled={state === "Active" ? false : true}
                                variant={`${
                                    value == selectedPair.left ||
                                    value == prevSelectedPair.prevLeft
                                        ? "contained"
                                        : "outlined"
                                }`}
                                color={`${
                                    selectedPairState.status === "Correct" &&
                                    prevSelectedPair.prevLeft == value
                                        ? "success"
                                        : selectedPairState.status === "Wrong"
                                        ? "error"
                                        : "info"
                                }`}
                                onClick={() => handleLeftSelect(value)}
                            >
                                {value}
                            </Button>
                        ))}
                </div>
                <div className="flex flex-col gap-5 ">
                    {pairs &&
                        pairs.map &&
                        pairs.map(({ left, right: value, state }) => (
                            <Button
                                disabled={state === "Active" ? false : true}
                                variant={`${
                                    value == selectedPair.right ||
                                    value == prevSelectedPair.prevRight
                                        ? "contained"
                                        : "outlined"
                                }`}
                                color={`${
                                    selectedPairState.status === "Correct" &&
                                    prevSelectedPair.prevRight == value
                                        ? "success"
                                        : selectedPairState.status === "Wrong"
                                        ? "error"
                                        : "info"
                                }`}
                                onClick={() => handleRightSelect(value)}
                            >
                                {value}
                            </Button>
                        ))}
                </div>
            </div>

            <Box sx={{ textAlign: "center", marginTop: 2 }}>
                {/* <Button variant="contained" color="primary" onClick={handleSelect}>
          Select
        </Button> */}
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleReset}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}
