import * as React from "react";
import {
    Box,
    Typography,
    Button,
    FormGroup,
    FormControlLabel,
    Switch,
} from "@mui/material";
import { toast } from "react-toastify";
import { shuffleArray } from "../../components/utils/random";
import vocab_200 from "./../../data/raw/korean_vocab_200.json";
import vocab_400 from "./../../data/raw/korean_vocab_400.json";
import vocab_1600 from "./../../data/raw/korean_1600_vocab.json";
import { SelectOptions } from "react-utility-yard";

interface Pair {
    [key: string]: string;
}

const _pairs: Pair = {
    study: "공부하다",
    eat: "먹다",
    sleep: "자다",
    work: "일하다",
    play: "놀다",
    read: "읽다",
    write: "쓰다",
    speak: "말하다",
    listen: "듣다",
    think: "생각하다",
    feel: "느끼다",
    see: "보다",
    hear: "듣다",
    smell: "냄새 맡다",
    taste: "맛보다",
    touch: "만지다",
    go: "가다",
    come: "오다",
    stand: "서다",
    sit: "앉다",
    head: "머리",
    face: "얼굴",
    eye: "눈",
    ear: "귀",
    nose: "코",
    mouth: "입",
    hand: "손",
    foot: "발",
    time: "시간",
    day: "날",
    year: "년",
    number: "숫자",
    color: "색깔",
};

interface slotSchema {
    id: number;
    value: { left: string | null; right: string | null };
    active: boolean;
    side: "left" | "right";
    selected: boolean;
    state: "Correct" | "Wrong" | "Pending" | "Idle";
}

// V1 only consists of the matching 5 pairs and move ahead..
export function MatchingPairsV2() {
    const [states, setStates] = React.useState<{
        score: number;
        correct: number;
        wrong: number;
        next: boolean;
        adaptive: boolean;
    }>({ score: 0, correct: 0, wrong: 0, next: false, adaptive: true });
    const [slots, setSlots] = React.useState<slotSchema[]>([]);
    const [source, setSource] = React.useState<string>("1600");

    // Adaptive SLots, Wrong Options Will be added here for revisits.

    const [adaptiveSlots, setAdaptiveSlots] = React.useState<Pair>({});

    const Init = () => {
        const _stack = states.adaptive ? 14 : 10;
        let _data: Pair = {};
        switch (source) {
            case "default":
                _data = _pairs;
                break;
            case "200":
                let obj_200: Pair = {};
                vocab_200.korean_vocab_200.map(({ alpha, value }) => {
                    obj_200[value] = alpha;
                });
                _data = obj_200;
                break;
            case "400":
                let obj_400: Pair = {};
                vocab_400["korean vocab 400"].map(({ alpha, value }) => {
                    obj_400[value] = alpha;
                });
                _data = obj_400;
                break;
            case "1600":
                let obj_1600: Pair = {};
                vocab_1600["korean_1600_vocab"].map(({ alpha, value }) => {
                    obj_1600[value] = alpha;
                });
                _data = obj_1600;
                break;
            default:
                break;
        }
        let new_pairs: any = shuffleArray(Object.entries(_data)).slice(0, 5);
        if (states.adaptive) {
            const entries = Object.entries(adaptiveSlots);
            new_pairs = shuffleArray([...new_pairs, ...entries.splice(0, 2)]);
            const remainingObject = Object.fromEntries(entries);
            setAdaptiveSlots(remainingObject);
        }
        const _slots = [];
        // console.log(new_pairs, adaptiveSlots);

        let stack = new_pairs.length * 2;
        for (let i = 0; i < stack; i++) {
            let idx = Math.floor(i % (stack / 2));
            const slot: slotSchema = {
                id: i,
                value: { left: new_pairs[idx][0], right: new_pairs[idx][1] },
                active: true,
                side: Math.floor(i / (stack / 2)) ? "left" : "right",
                selected: false,
                state: "Idle",
            };
            _slots.push(slot);
        }

        setSlots([
            ..._slots.slice(0, stack / 2),
            ...shuffleArray(_slots.slice(stack / 2)),
        ]);
    };

    const makeCleanUp = async () => {
        // console.log("Clean Up Run.");
        if (!slots.length) return;
        setSlots([
            ...slots.map((slot) => {
                if (slot.state === "Correct") {
                    return {
                        ...slot,
                        state: "Idle",
                        active: false,
                        selected: false,
                    } as slotSchema;
                } else if (slot.state === "Wrong") {
                    return {
                        ...slot,
                        state: "Idle",
                        active: true,
                        selected: false,
                    } as slotSchema;
                } else return { ...slot };
            }),
        ]);
        // check for next
        // let is_next = false;
        // let _slots = [...slots]
        // _slots.map((slot) => (!slot.active ? (is_next = true) : null));
        // if (is_next) setStates({ ...states, next: true });
    };

    const handleSelect = ({
        value,
        active,
        state,
        selected,
        side,
        id,
    }: slotSchema) => {
        let updated_slots = [];
        var selected_slot_left: any = null;
        var selected_slot_right: any = null;

        // check for same value and Uncheck ( Maintain the Single selected)
        updated_slots = slots.map((_slot) => {
            let slot: slotSchema = _slot;
            // consider the Side as its a Break-point
            if (slot.side === side) {
                // selected or not
                if (selected) {
                    if (slot.id === id) {
                        return { ...slot, selected: false };
                    }
                } else {
                    if (slot.id === id) {
                        return { ...slot, selected: true };
                    } else return { ...slot, selected: false };
                }
            }
            return { ...slot };
        });

        // check for current selected wrong or correct
        updated_slots.map((_slot) => {
            let slot = _slot;
            if (slot.selected) {
                if (slot.side === "left") selected_slot_left = slot;
                else selected_slot_right = slot;
            }
        });

        // making sure that slots are not null
        if (selected_slot_left && selected_slot_right) {
            if (
                selected_slot_left?.value.left ===
                selected_slot_right?.value.left
            ) {
                // state is correct
                updated_slots = updated_slots.map((_slot) => {
                    let slot = _slot;
                    if (slot.side === "left") {
                        if (selected_slot_left.id === slot.id) {
                            return { ...slot, state: "Correct" } as slotSchema;
                        }
                    } else {
                        if (selected_slot_right.id === slot.id) {
                            return { ...slot, state: "Correct" } as slotSchema;
                        }
                    }
                    return { ...slot };
                });
                setStates({
                    ...states,
                    score: states.score + 1,
                    correct: states.correct + 1,
                });
            } else {
                // state is Wrong
                let wrong_obj: Pair = {};
                wrong_obj[selected_slot_left.value.left] =
                    selected_slot_left.value.right;
                if (states.adaptive) {
                    setAdaptiveSlots({ ...adaptiveSlots, ...wrong_obj });
                }

                updated_slots = updated_slots.map((_slot) => {
                    let slot = _slot;
                    if (slot.side === "left") {
                        if (selected_slot_left.id === slot.id) {
                            return { ...slot, state: "Wrong" } as slotSchema;
                        }
                    } else {
                        if (selected_slot_right.id === slot.id) {
                            return { ...slot, state: "Wrong" } as slotSchema;
                        }
                    }
                    return { ...slot };
                });
                setStates({
                    ...states,
                    score: states.score - 1,
                    wrong: states.wrong + 1,
                });
            }
        }

        setSlots([...updated_slots]);
    };

    const handleReset = () => {
        Init();
    };

    React.useEffect(() => {
        Init();
        return () => {};
    }, [source]);

    React.useEffect(() => {
        // let is_next = false;
        // let _slots = [...slots];
        // _slots.map((slot) => {
        //     if (!slot.active) {
        //         is_next = true;
        //         return null;
        //     } else {
        //         return null;
        //     }
        // });
        // if (is_next) setStates({ ...states, next: true });

        setTimeout(() => {
            makeCleanUp();
        }, 300);
    }, [states]);

    return (
        <Box
            sx={{
                width: "100%",
                maxWidth: 360,
                margin: "auto",
                alignItems: "center",
                alignContent: "center",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
            }}
        >
            <div className="flex gap-2">
                {/* <SelectOptions
                    options={[
                        "200",
                        // { key: "200", value: "200" },
                        // { key: "200", value: "200" },
                        // { key: "200", value: "1600" },
                    ]}
                    onClick={() => null}
                    // onClick={(v: any) => setSource(v)}
                />
                <Button variant="outlined" onClick={() => setSource("200")}>
                    Fetch 0-200
                </Button>
                <Button variant="outlined" onClick={() => setSource("400")}>
                    Fetch 201-400
                </Button>
                <Button variant="outlined" onClick={() => setSource("1600")}>
                    Fetch 1600
                </Button> */}
            </div>
            <Typography variant="h5" align="center" gutterBottom>
                Match The Pairs ✨
            </Typography>
            <Typography variant="h6" align="center" gutterBottom>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                checked={states.adaptive}
                                onChange={(ada) =>
                                    setStates({
                                        ...states,
                                        adaptive: ada.target.checked,
                                    })
                                }
                            />
                        }
                        label="Adaptive"
                    />
                </FormGroup>
            </Typography>
            <Typography variant="h5" align="center" gutterBottom>
                <div className="flex gap-3 justify-center items-center">
                    <div className=" flex gap-1 bg-sky-100 rounded-md p-2">
                        <div className="bg-sky-200  rounded-md px-2">
                            Score :
                        </div>
                        <div className="bg-sky-200  rounded-md px-2">
                            {states.score}
                        </div>
                    </div>
                    <div className=" flex gap-2 bg-gray-100 rounded-md p-2">
                        <div className=" bg-green-300 rounded-md px-2">
                            {states.correct}
                        </div>
                        /
                        <div className="bg-red-300 rounded-md px-2">
                            {states.wrong}
                        </div>
                    </div>
                </div>
            </Typography>
            <div className="flex gap-10 justify-around">
                <div className="flex flex-col gap-5 ">
                    {slots
                        .filter((slot) => slot.side === "left")
                        .map(({ value, active, state, selected, side, id }) => (
                            <Button
                                key={id}
                                disabled={active ? false : true}
                                variant={`${
                                    selected ? "contained" : "outlined"
                                }`}
                                color={`${
                                    state === "Correct"
                                        ? "success"
                                        : state === "Wrong"
                                        ? "error"
                                        : "info"
                                }`}
                                onClick={() =>
                                    handleSelect({
                                        value,
                                        active,
                                        state,
                                        selected,
                                        id,
                                        side,
                                    })
                                }
                            >
                                {value.left}
                            </Button>
                        ))}
                </div>
                <div className="flex flex-col gap-5 ">
                    {slots
                        .filter((slot) => slot.side === "right")
                        .map(({ value, active, state, selected, side, id }) => (
                            <Button
                                key={id}
                                disabled={active ? false : true}
                                variant={`${
                                    selected ? "contained" : "outlined"
                                }`}
                                color={`${
                                    state === "Correct"
                                        ? "success"
                                        : state === "Wrong"
                                        ? "error"
                                        : "info"
                                }`}
                                onClick={() =>
                                    handleSelect({
                                        value,
                                        active,
                                        state,
                                        selected,
                                        side,
                                        id,
                                    })
                                }
                            >
                                {value.right}
                            </Button>
                        ))}
                </div>
            </div>

            <Box sx={{ textAlign: "center", marginTop: 2 }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleReset}
                    // disabled={!states.next}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}
