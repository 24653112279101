import * as React from "react";
import {
    Box,
    Typography,
    Button,
    FormGroup,
    FormControlLabel,
    Switch,
    Skeleton,
    LinearProgress,
    Drawer,
    Slider,
} from "@mui/material";
import { shuffleArray } from "../../components/utils/random";
import axios from "axios";
import { useKardzAppDispatch, useKardzAppSelector } from "../../store/store";
import { korean_update_words_list } from "../../store/actions/actionTypes";
import { toast } from "react-toastify";
import SettingsIcon from "@mui/icons-material/Settings";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

interface wordSchema {
    _id?: string;
    alpha: string;
    value: string;
    meta: { correct: number; wrong: number };
}

interface slotSchema {
    id: number;
    value: { left: string | null; right: string | null };
    raw: wordSchema | null;
    active: boolean;
    side: "left" | "right";
    selected: boolean;
    state: "Correct" | "Wrong" | "Pending" | "Idle";
}

function speakText(
    text: string,
    lan: "En" | "Kr",
    en_audio: boolean = true,
    kr_audio: boolean = true
) {
    const utterance = new SpeechSynthesisUtterance(text);
    let _voice = "";
    if (lan === "Kr") {
        utterance.lang = "ko-KR";
        _voice = "Google 한국의";
        // let _voice = "Microsoft Heami - Korean (Korean)"
    }
    utterance.voice = speechSynthesis
        .getVoices()
        .find((voice) => voice.name === _voice)!; // Replace with the desired female voice name

    // Set rate and pitch for clarity
    utterance.rate = 0.9; // Adjust as needed
    utterance.pitch = 1.0; // Adjust as needed
    if ((en_audio && lan == "En") || (kr_audio && lan === "Kr"))
        speechSynthesis.speak(utterance);
}

// V1 only consists of the matching 5 pairs and move ahead..
export function MatchingPairsV4() {
    const { all_words } = useKardzAppSelector((state) => state.korean);
    const [states, setStates] = React.useState<{
        score: number;
        correct: number;
        wrong: number;
        next: boolean;
        adaptive: boolean;
        progress: number;
        shuffle: boolean;
        settings: boolean;
        accuracy_gap: number;
        en_audio: boolean;
        kr_audio: boolean;
    }>({
        score: 0,
        correct: 0,
        wrong: 0,
        next: false,
        adaptive: true,
        progress: 0,
        shuffle: false,
        settings: false,
        accuracy_gap: 1,
        en_audio: true,
        kr_audio: true,
    });

    const [loading, setLoading] = React.useState<boolean>(false);
    const [slots, setSlots] = React.useState<slotSchema[]>([]);
    // const [source, setSource] = React.useState<string>("1600");

    // Adaptive SLots, Wrong Options Will be added here for revisits.
    const [adaptiveSlots, setAdaptiveSlots] = React.useState<wordSchema[]>([]);
    const dispatch = useKardzAppDispatch();
    const dispatch_action = React.useCallback(
        (action: any) => dispatch(action),
        []
    );

    const get_progres_count = (data: any) => {
        let progress = 0;
        data.map((word: wordSchema) => {
            let wrong = word.meta.wrong | 0;
            let correct = word.meta.correct! | 0;
            if (Math.abs(wrong - correct) >= states.accuracy_gap) {
                progress = progress + 1;
            }
        });
        setStates({
            ...states,
            progress: Math.floor((progress * 100) / data.length),
        });
    };

    const fetch_words = async () => {
        setLoading(true);
        const url = "https://admin-io-vercel.vercel.app/kardz/list";
        try {
            const { data } = await axios.post(url, {});

            if (data.length) {
                get_progres_count(data);
                dispatch_action({
                    type: korean_update_words_list,
                    payload: [...data],
                });
            }
        } catch (error) {
            console.error("Error fetching document:", error);
            throw error; // Rethrow the error for further handling
        }
        setLoading(false);
    };

    const sync_word_server = async (word: any, correct: boolean) => {
        const url = "https://admin-io-vercel.vercel.app/kardz/update";

        let { meta } = word;
        if (correct) {
            if (!meta.correct) meta.correct = 1;
            else meta.correct = meta.correct + 1;
        } else {
            if (!meta.wrong) meta.wrong = 1;
            else meta.wrong = meta.wrong + 1;
        }

        try {
            const { data } = await axios.post(url, {
                ...word,
                meta,
            });
            toast.info("Updated!");
            // dispatch_action({ type: korean_update_words_list, payload: data });
        } catch (error) {
            console.error("Error fetching document:", error);
            throw error; // Rethrow the error for further handling
        }
    };

    const Init = () => {
        const _stack = states.adaptive ? 14 : 10;

        // get data
        let words: wordSchema[] = all_words?.length ? [...all_words] : [];

        // Sort words based on correct score. Or Just shuffle

        let refined_words: wordSchema[] = states.shuffle
            ? shuffleArray(words)
            : words.sort(
                  (a, b) =>
                      Math.abs(
                          a.meta.correct / (a.meta.wrong + a.meta.correct)
                      ) -
                      Math.abs(b.meta.correct / (b.meta.wrong + b.meta.correct))
                  //   a.meta.correct - b.meta.correct
              );

        refined_words = refined_words.slice(0, 5);

        if (states.adaptive) {
            const entries = [...adaptiveSlots];
            refined_words = shuffleArray([
                ...refined_words,
                ...entries.splice(0, 2),
            ]);
            // const remainingObject = Object.fromEntries(entries);
            setAdaptiveSlots(entries);
        }
        const _slots = [];
        // console.log(new_pairs, adaptiveSlots);

        let stack = refined_words.length * 2;
        for (let i = 0; i < stack; i++) {
            let idx = Math.floor(i % (stack / 2));
            const slot: slotSchema = {
                id: i,
                value: {
                    left: refined_words[idx].value,
                    right: refined_words[idx].alpha,
                },
                raw: refined_words[idx],
                active: true,
                side: Math.floor(i / (stack / 2)) ? "left" : "right",
                selected: false,
                state: "Idle",
            };
            _slots.push(slot);
        }

        setSlots([
            ..._slots.slice(0, stack / 2),
            ...shuffleArray(_slots.slice(stack / 2)),
        ]);
    };

    const makeCleanUp = async () => {
        // console.log("Clean Up Run.");
        if (!slots.length) return;
        setSlots([
            ...slots.map((slot) => {
                if (slot.state === "Correct") {
                    return {
                        ...slot,
                        state: "Idle",
                        active: false,
                        selected: false,
                    } as slotSchema;
                } else if (slot.state === "Wrong") {
                    return {
                        ...slot,
                        state: "Idle",
                        active: true,
                        selected: false,
                    } as slotSchema;
                } else return { ...slot };
            }),
        ]);
        // check for next
        // let is_next = false;
        // let _slots = [...slots]
        // _slots.map((slot) => (!slot.active ? (is_next = true) : null));
        // if (is_next) setStates({ ...states, next: true });
    };

    const handleSelect = ({
        value,
        active,
        state,
        selected,
        side,
        id,
        raw,
    }: slotSchema) => {
        let updated_slots = [];

        let selected_slot_left: any = null;
        let selected_slot_right: any = null;

        // Text To speech
        if (side === "left") {
            speakText(value.left || "", "En", states.en_audio, states.kr_audio);
        } else {
            speakText(
                value.right || "",
                "Kr",
                states.en_audio,
                states.kr_audio
            );
        }

        // check for same value and Uncheck ( Maintain the Single selected)
        updated_slots = slots.map((_slot) => {
            let slot: slotSchema = _slot;
            // consider the Side as its a Break-point
            if (slot.side === side) {
                // selected or not
                if (selected) {
                    if (slot.id === id) {
                        return { ...slot, selected: false };
                    }
                } else {
                    if (slot.id === id) {
                        return { ...slot, selected: true };
                    } else return { ...slot, selected: false };
                }
            }
            return { ...slot };
        });

        // check for current selected wrong or correct
        updated_slots.map((_slot) => {
            let slot = _slot;
            if (slot.selected) {
                if (slot.side === "left") selected_slot_left = slot;
                else selected_slot_right = slot;
            }
        });

        // making sure that slots are not null
        if (selected_slot_left && selected_slot_right) {
            if (
                selected_slot_left?.value.left ===
                selected_slot_right?.value.left
            ) {
                // state is correct
                sync_word_server(selected_slot_left.raw, true);
                updated_slots = updated_slots.map((_slot) => {
                    let slot = _slot;
                    if (slot.side === "left") {
                        if (selected_slot_left!.id === slot.id) {
                            return { ...slot, state: "Correct" } as slotSchema;
                        }
                    } else {
                        if (selected_slot_right!.id === slot.id) {
                            return { ...slot, state: "Correct" } as slotSchema;
                        }
                    }
                    return { ...slot };
                });
                setStates({
                    ...states,
                    score: states.score + 1,
                    correct: states.correct + 1,
                });
            } else {
                // state is Wrong
                // let wrong_obj: Pair = {};
                // wrong_obj[selected_slot_left.value.left] =
                //     selected_slot_left.value.right;
                if (states.adaptive) {
                    setAdaptiveSlots([
                        ...adaptiveSlots,
                        selected_slot_left.raw,
                        selected_slot_right.raw,
                    ]);
                }

                sync_word_server(selected_slot_left.raw, false);
                updated_slots = updated_slots.map((_slot) => {
                    let slot = _slot;
                    if (slot.side === "left") {
                        if (selected_slot_left.id === slot.id) {
                            return { ...slot, state: "Wrong" } as slotSchema;
                        }
                    } else {
                        if (selected_slot_right.id === slot.id) {
                            return { ...slot, state: "Wrong" } as slotSchema;
                        }
                    }
                    return { ...slot };
                });
                setStates({
                    ...states,
                    score: states.score - 1,
                    wrong: states.wrong + 1,
                });
            }
        }

        setSlots([...updated_slots]);
    };

    const handleReset = () => {
        Init();
    };

    React.useEffect(() => {
        Init();
        return () => {};
    }, [all_words]);

    React.useEffect(() => {
        fetch_words();
        return () => {};
    }, []);

    React.useEffect(() => {
        setTimeout(() => {
            makeCleanUp();
        }, 300);
    }, [states]);

    return (
        <Box
            sx={{
                width: "100%",
                maxWidth: 360,
                margin: "auto",
                alignItems: "center",
                alignContent: "center",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
            }}
        >
            <Typography variant="h5" align="center" gutterBottom>
                Match The Pairs ✨
            </Typography>
            <Timer />
            <Typography variant="h6" align="center" gutterBottom>
                <div className="flex gap-2 flex-row">
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                checked={states.adaptive}
                                onChange={(ada) =>
                                    setStates({
                                        ...states,
                                        adaptive: ada.target.checked,
                                    })
                                }
                            />
                        }
                        label="Adaptive"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                checked={states.shuffle}
                                onChange={(ada) =>
                                    setStates({
                                        ...states,
                                        shuffle: ada.target.checked,
                                    })
                                }
                            />
                        }
                        label="Shuffle"
                    />
                    <SettingsIcon
                        cursor="pointer"
                        onClick={() =>
                            setStates({ ...states, settings: !states.settings })
                        }
                    />
                </div>
                <Drawer
                    anchor={"right"}
                    open={states.settings}
                    onClose={() =>
                        setStates({ ...states, settings: !states.settings })
                    }
                >
                    <div className="flex flex-col gap-3 px-7 max-w-[400px]">
                        <div className="flex justify-between py-2 items-center">
                            <div className="font-bold text-lg"> Settings</div>
                            <IconButton
                                className="bg-green-300 p-1"
                                onClick={() =>
                                    setStates({
                                        ...states,
                                        settings: !states.settings,
                                    })
                                }
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Box sx={{ width: 300 }}>
                            <Typography id="input-slider" gutterBottom>
                                Accuracy Gap {states.accuracy_gap}
                            </Typography>
                            <Slider
                                onChange={(_, val) =>
                                    setStates({
                                        ...states,
                                        accuracy_gap: val as number,
                                    })
                                }
                                value={states.accuracy_gap}
                                aria-label="Temperature"
                                defaultValue={30}
                                getAriaValueText={() =>
                                    `${states.accuracy_gap}`
                                }
                                valueLabelDisplay="auto"
                                shiftStep={30}
                                step={1}
                                marks
                                min={0}
                                max={10}
                            />
                        </Box>
                        <div className="flex gap-2 flex-row">
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        checked={states.en_audio}
                                        onChange={(ada) =>
                                            setStates({
                                                ...states,
                                                en_audio: ada.target.checked,
                                            })
                                        }
                                    />
                                }
                                label="En Audio"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        checked={states.kr_audio}
                                        onChange={(ada) =>
                                            setStates({
                                                ...states,
                                                kr_audio: ada.target.checked,
                                            })
                                        }
                                    />
                                }
                                label="Kr Audio"
                            />
                        </div>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "300px",
                            }}
                        >
                            <Box sx={{ width: "100%", mx: 1 }}>
                                <LinearProgress
                                    variant="determinate"
                                    color="success"
                                    value={states.progress}
                                />
                            </Box>
                            <Box sx={{ minWidth: 35 }}>
                                <Typography
                                    variant="body2"
                                    sx={{ color: "text.secondary" }}
                                >{`${Math.round(
                                    states.progress
                                )}%`}</Typography>
                            </Box>
                        </Box>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => fetch_words()}
                            // disabled={!states.next}
                        >
                            Get Words
                        </Button>
                        <div className="flex gap-1 flex-col overflow-y-scroll h-96">
                            {all_words.map((word: wordSchema, i: number) => (
                                <div
                                    className=" flex gap-2 px-1"
                                    style={{
                                        background: `linear-gradient(to right, #dff7d7, #dff7d7  ${
                                            word
                                                ? (100 * word?.meta.correct) /
                                                  (word?.meta.correct +
                                                      word?.meta.wrong)
                                                : ""
                                        }%, #f7d7d7 ${
                                            word
                                                ? (100 * word?.meta.correct) /
                                                  (word?.meta.correct +
                                                      word?.meta.wrong)
                                                : ""
                                        }%, #f7d7d7)`,
                                    }}
                                >
                                    <div>{i}</div>
                                    <div>{word.alpha}</div>
                                    <div>{word.value}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Drawer>
            </Typography>
            <Typography variant="h5" align="center" gutterBottom>
                <div className="flex gap-3 justify-center items-center">
                    <div className=" flex gap-1 bg-sky-100 rounded-md p-2">
                        <div className="bg-sky-200  rounded-md px-2">
                            Score :
                        </div>
                        <div className="bg-sky-200  rounded-md px-2">
                            {states.score}
                        </div>
                    </div>
                    <div className=" flex gap-2 bg-gray-100 rounded-md p-2">
                        <div className=" bg-green-300 rounded-md px-2">
                            {states.correct}
                        </div>
                        /
                        <div className="bg-red-300 rounded-md px-2">
                            {states.wrong}
                        </div>
                    </div>
                </div>
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", width: "300px" }}>
                <Box sx={{ width: "100%", mx: 1 }}>
                    <LinearProgress
                        variant="determinate"
                        color="success"
                        value={states.progress}
                    />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                    >{`${Math.round(states.progress)}%`}</Typography>
                </Box>
            </Box>
            {!loading ? (
                <div className="flex gap-10 justify-around">
                    <div className="flex flex-col gap-5 ">
                        {slots
                            .filter((slot) => slot.side === "left")
                            .map(
                                ({
                                    value,
                                    active,
                                    state,
                                    selected,
                                    side,
                                    id,
                                    raw,
                                }) => (
                                    <Button
                                        style={{
                                            background: active
                                                ? ""
                                                : `linear-gradient(to right, #dff7d7, #dff7d7  ${
                                                      raw
                                                          ? (100 *
                                                                raw?.meta
                                                                    .correct) /
                                                            (raw?.meta.correct +
                                                                raw?.meta.wrong)
                                                          : ""
                                                  }%, #f7d7d7 ${
                                                      raw
                                                          ? (100 *
                                                                raw?.meta
                                                                    .correct) /
                                                            (raw?.meta.correct +
                                                                raw?.meta.wrong)
                                                          : ""
                                                  }%, #f7d7d7)`,
                                        }}
                                        key={id}
                                        disabled={active ? false : true}
                                        variant={`${
                                            selected ? "contained" : "outlined"
                                        }`}
                                        color={`${
                                            state === "Correct"
                                                ? "success"
                                                : state === "Wrong"
                                                ? "error"
                                                : "info"
                                        }`}
                                        onClick={() =>
                                            handleSelect({
                                                value,
                                                active,
                                                state,
                                                selected,
                                                id,
                                                side,
                                                raw,
                                            })
                                        }
                                    >
                                        {value.left}
                                    </Button>
                                )
                            )}
                    </div>
                    <div className="flex flex-col gap-5 ">
                        {slots
                            .filter((slot) => slot.side === "right")
                            .map(
                                ({
                                    value,
                                    active,
                                    state,
                                    selected,
                                    side,
                                    id,
                                    raw,
                                }) => (
                                    <Button
                                        style={{
                                            background: active
                                                ? ""
                                                : `linear-gradient(to right, #dff7d7, #dff7d7  ${
                                                      raw
                                                          ? (100 *
                                                                raw?.meta
                                                                    .correct) /
                                                            (raw?.meta.correct +
                                                                raw?.meta.wrong)
                                                          : ""
                                                  }%, #f7d7d7 ${
                                                      raw
                                                          ? (100 *
                                                                raw?.meta
                                                                    .correct) /
                                                            (raw?.meta.correct +
                                                                raw?.meta.wrong)
                                                          : ""
                                                  }%, #f7d7d7)`,
                                        }}
                                        key={id}
                                        disabled={active ? false : true}
                                        variant={`${
                                            selected ? "contained" : "outlined"
                                        }`}
                                        color={`${
                                            state === "Correct"
                                                ? "success"
                                                : state === "Wrong"
                                                ? "error"
                                                : "info"
                                        }`}
                                        onClick={() =>
                                            handleSelect({
                                                value,
                                                active,
                                                state,
                                                selected,
                                                side,
                                                id,
                                                raw,
                                            })
                                        }
                                    >
                                        {value.right}
                                    </Button>
                                )
                            )}
                    </div>
                </div>
            ) : (
                <div className="flex gap-4 ">
                    {/* <Skeleton variant="rectangular" animation="wave" width={310} height={300} /> */}
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        width={150}
                        height={300}
                    />
                    <Skeleton
                        variant="rounded"
                        animation="wave"
                        width={150}
                        height={300}
                    />
                </div>
            )}

            <Box sx={{ textAlign: "center", marginTop: 2 }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleReset}
                    // disabled={!states.next}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}

function Timer() {
    const [seconds, setSeconds] = React.useState(0);
    const [isRunning, setIsRunning] = React.useState(true);
    React.useEffect(() => {
        const intervalId = setInterval(() => {
            if (isRunning) {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [isRunning]);

    const handleStart = () => {
        setIsRunning(true);
    };

    const handlePause = () => {
        setIsRunning(false);
    };

    const handleReset = () => {
        setIsRunning(false);
        setSeconds(0);
    };

    return (
        <div className="flex gap-1 bg-gray-200 rounded-md p-1">
            <div className="flex gap-3 justify-center items-center">
                <div className=" flex gap-1 bg-sky-100 rounded-md p-1">
                    <div className="bg-sky-200  rounded-md px-2">
                        {formatTime(seconds).hoursString}
                    </div>
                    <div className="bg-sky-200  rounded-md px-2">
                        {formatTime(seconds).minutesString}
                    </div>
                    <div className="bg-sky-200  rounded-md px-2">
                        {formatTime(seconds).secondsString}
                    </div>
                </div>
            </div>
            <div className="flex gap-2 bg-gray-300 rounded-md p-1">
                <div className="bg-gray-200  rounded-md px-0">
                    {!isRunning ? (
                        <button onClick={handleStart}>
                            <PlayArrowIcon />
                        </button>
                    ) : (
                        <button onClick={handlePause}>
                            <PauseIcon />
                        </button>
                    )}
                </div>
                <div className="bg-gray-200  rounded-md px-0">
                    <button onClick={handleReset}>
                        <RestartAltIcon />
                    </button>
                </div>
            </div>
        </div>
    );
}

function formatTime(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const hoursString =
        hours > 0
            ? `${hours}h   
   `
            : "0h";
    const minutesString = minutes > 0 ? `${minutes}m ` : "0m";
    const secondsString = remainingSeconds > 0 ? `${remainingSeconds}s` : "0s";

    return { hoursString, minutesString, secondsString };
}
