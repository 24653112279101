// import { KardzAction, KardzState } from "../../../type";
import { KardzAction, KardzState } from "../../type";
import * as actionTypes from "../actions/actionTypes";
// import { RootKardzState } from "../store";

const Kardz_init_state: KardzState = {
  gameOn: false,
  currentIdx: 0,
  score: 0,
  cardType: "keyPair",
  checkMode: true,
  list: [],
  checkAgainst: null,
  showAnswer: true,
  kardzMenuList: [],
  KardzName: null,
};

export const kardzReducer = (
  state: KardzState = Kardz_init_state,
  action: KardzAction
): KardzState => {
  switch (action.type) {
    case actionTypes.kardz_update_index_list:
      return { ...state, kardzMenuList: action.payload };

    case actionTypes.kardz_set_card_name:
      return { ...state, KardzName: action.payload };

    case actionTypes.kardz_toggle_game:
      return { ...state, gameOn: action.payload };

    case actionTypes.kardz_set_check_against:
      console.log(action);
      return { ...state, checkAgainst: action.payload };

    case actionTypes.kardz_toggle_showAnswer:
      return { ...state, showAnswer: action.payload };

    case actionTypes.kardz_update_list:
      // console.log("Update cardz list", action.payload);
      let new_list: any[] = [];
      if (action.payload && action.payload.length > 0)
        new_list = [...action.payload];
      return { ...state, list: new_list };

    case actionTypes.kardz_update_kard_type:
      // console.log("Update card Type", action.payload);
      return { ...state, cardType: action.payload };

    case actionTypes.kardz_toggle_checkMode:
      return { ...state, checkMode: action.payload };

    case actionTypes.kardz_soft_reset:
      return { ...state, gameOn: false, currentIdx: 0 };

    case actionTypes.kardz_score_increment:
      return { ...state, score: state.score + 1 };

    case actionTypes.kardz_next_idx:
      return { ...state, currentIdx: state.currentIdx + 1 };

    case actionTypes.kardz_prev_idx:
      return { ...state, currentIdx: state.currentIdx && state.currentIdx - 1 };

    default:
      return { ...state };
  }
};

// export const kardzReducer = (
//   state: KardzState = Kardz_init_state,
//   action: KardzAction,
// ): KardzState => {
//   switch (action.type) {
//     case actionTypes.kardz_toggle_game:
//       return { ...state, gameOn: action.payload };

//     case actionTypes.kardz_update_list:
//       console.log("Update cardz list", action.payload);
//       let new_list: any[] = [];
//       if (action.payload && action.payload.length > 0)
//         new_list = [...action.payload];
//       return { ...state, list: new_list };

//     case actionTypes.kardz_update_kard_type:
//       console.log("Update card Type", action.payload);
//       return { ...state, cardType: action.payload };

//     case actionTypes.kardz_toggle_checkMode:
//       return { ...state, checkMode: action.payload };

//     case actionTypes.kardz_soft_reset:
//       return { ...state, gameOn: false, currentIdx: 0 };

//     case actionTypes.kardz_score_increment:
//       return { ...state, score: state.score + 1 };

//     case actionTypes.kardz_next_idx:
//       return { ...state, currentIdx: state.currentIdx + 1 };

//     case actionTypes.kardz_prev_idx:
//       return { ...state, currentIdx: state.currentIdx && state.currentIdx - 1 };

//     default:
//       return { ...state };
//   }
// };
